<template>
  <el-container class="music_manage">
    <el-main>
      <el-scrollbar class="music_table">
        <table>
          <colgroup>
            <col class="music_id">
            <col class="music_title">
            <col class="music_author">
            <col class="music_duration">
            <col class="music_edit">
          </colgroup>
          <thead>
          <tr>
            <th colspan="1" rowspan="1">
              <div>ID</div>
            </th>
            <th colspan="1" rowspan="1">
              <div>标题</div>
            </th>
            <th colspan="1" rowspan="1">
              <div>作者</div>
            </th>
            <th colspan="1" rowspan="1">
              <div>时长</div>
            </th>
            <th colspan="1" rowspan="1">
              <div>操作</div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in music_list" :key="item.id">
            <th colspan="1" rowspan="1">
              <div>{{ item.id }}</div>
            </th>
            <th colspan="1" rowspan="1">
              <div>{{ item.title }}</div>
            </th>
            <th colspan="1" rowspan="1">
              <div>{{ item.author }}</div>
            </th>
            <th colspan="1" rowspan="1">
              <div>{{ item.duration }}</div>
            </th>
            <th colspan="1" rowspan="1">
              <div>
                <el-button class="ri-delete-bin-line delete_button" size='large'
                           @click="delete_music(item.id,index)"></el-button>
              </div>
            </th>
          </tr>
          </tbody>
        </table>
      </el-scrollbar>
    </el-main>
    <el-aside style="margin: 20px 20px 20px 0;overflow: hidden" width="250px">
      <div class="info">音乐文件共有<br>{{ music_list.length }}个</div>
      <el-upload v-model:file-list="file_list"
                 action="/api/music_upload"
                 :headers="token()"
                 accept="audio/mpeg,audio/flac"
                 :on-error="upload_error"
                 :before-upload="before_upload"
                 multiple>
        <el-button class="upload_button">上传</el-button>
      </el-upload>
    </el-aside>
  </el-container>
</template>

<script>
export default {
  name: "Music_manage",
  data() {
    return {
      music_list: [],
      file_list: [],
      width: 0
    }
  },
  computed: {}
  ,
  methods: {
    token() {
      return {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    },
    test() {
      this.width += 20
    },
    delete_music(music_id, index) {
      let access_token = localStorage.getItem('token')
      if (access_token) {
        this.$axios.post('/api/delete_music', {
          id: music_id
        }, {
          headers:  {
          'Authorization': 'Bearer ' + access_token
        }
        }).then(res => {
          this.$ElNotification({
            title: res.data,
            type: 'success'
          })
          this.music_list.splice(index, 1);
        }).catch(err=>{
          console.error(err)
          this.$ElNotification({
            title: '权限验证失败',
            type: 'success'
          })
        })
      } else {
        this.$ElNotification({
          title: '未登录用户',
          type: 'error'
        })
      }
    },
    upload_error(error) {
      let message = '上传失败'
      if (error.status === 401) {
        message = '权限验证失败'
      }
      this.$ElNotification({
        title: message,
        type: 'error'
      })
    },
    before_upload(file) {
      let isMusic = file.type === 'audio/mpeg' || file.type === 'audio/flac'
      if (!isMusic) {
        this.$ElNotification({
          title: '上传文件只能是mp3或flac格式',
          type: 'error'
        })
        return Promise.reject('file_error')
      } else {
        let access_token = localStorage.getItem('token')
        if (!access_token) {
          this.$ElNotification({
            title: '未登录用户',
            type: 'error'
          })
          return Promise.reject()
        }
      }
    },

  }
  ,
  created() {
    this.$axios.get('/api/get_musiclist').then(res => {
      this.music_list = res.data
    })
  }
}
</script>

<style scoped>
.music_manage {
  height: 100%;
  width: 100%;
  margin: auto;
}


.music_table {
  height: 100%;
  background-color: #ffffff;
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  position: sticky;
  background-color: #FFF;
  top: 0;
}

tr {
  border-bottom: 1px solid #ebeef5;
  height: 50px;
}

tbody th {
  font-weight: normal;
}

th > div {
  padding-left: 15px;
  text-align: left;
}


.info {
  display: flex;
  width: 250px;
  height: 150px;
  background-color: #fdfdfd;
  border-radius: 15px;
  margin-bottom: 10px;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
}

.delete_button {
  border: none;
  font-size: 20px;
  padding-inline: 10px;
}

.upload_button {
  width: 250px;
  height: 50px;
  font-size: 20px;
  border-radius: 15px;
}

:deep(.el-upload-list__item-info a) {
  width: 200px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
</style>